<template>
  <div class="card-tm">
    <div class="gradiant-bg2 py-5">
      <div class="container container-row">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-lg-5">
            <h4 class="fw-600 text-dark">One Step Buy <span class="text-primary">Crypto</span></h4>
            <p class="fs-16 py-4 text-dark">Just one more step to go to create wealth with most leading exchange. Simple
              and securely</p>
          </div>
          <div class="col-12 col-lg-6 text-lg-end text-center">
            <img :src="require(`../../../public/assets/images/layout/Frame.png`)" class="img-fluid w-75" />
          </div>
        </div>
      </div>
    </div>
    <div class="container container-row">
      <div class="row gy-3 py-2">
        <div class="col-12" v-for="(item, index) of cards" :key="index">
          <div class="card shadow-sm py-3">
            <div class="card-body">
              <div class="row justify-content-between gy-3 align-items-center">
                <div class="col-lg col-12 text-center">
                  <img :src="require(`../../../public/assets/images/layout/${item.img}`)" class="img-fluid w-50" />
                </div>
                <div class="col-lg-10 col-12">
                  <div class="d-flex gap-2 justify-content-between align-items-center flex-wrap">
                    <div>
                      <h6 class="fw-600 fs-18">{{item.title}}</h6>
                      <p>{{ item.subtitle }}</p>
                    </div>
                    <div>
                      <router-link :to="item.link" class="btn btn-primary text-white">Coming Soon</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuyCrypto',
  data:() => ({
    cards:[
      {title:'Cards', subtitle:'Buy crytpo with card at lower fees', link:'/coming', img:'Frame.png'},
      {title:'Crypto Deposit', subtitle:'Buy crytpo with card at lower fees', link:'/coming', img:'Frame.png'},
    ],
  }),
}
</script>